@font-face {
    font-family: "Poppins";
    src: url("../../fonts/Poppins-400.ttf") format("truetype");
    font-display: swap;
}

.navbar-desktop {
    display: flex;
    align-items: center;
    background-color: black;
    justify-content: space-between;
    padding: 10px 30px;
    height: 90px;
    font-family: "Poppins";
}
    
.navbar-logo {
    display: flex;
    align-items: center;
    text-decoration: none;
}

.navbar-center-links {
    position: absolute;
    left: 50%;
    transform: translateX(-50%); 
    display: flex;
    align-items: center;
}

.navbar-link {
    font-size: 18px;
    font-weight: 700;
    text-decoration: none;
    color: white;
    margin: 0 15px; 
    padding: 0 20px;
    font-size: 14px;
}

.navbar-logo-image {
    width: 150px;
}

.navbar-language-selector-container {
    position: relative;
    cursor: pointer;
}

.navbar-language-icon{
    width: 25px;
    height: 25px;
    filter: brightness(0) invert(1); 
}

.navbar-language-selector-dropdown {
    position: absolute;
    top: 100%;
    right: 0;
    border: 1px solid #ccc;
    background-color: #ffffff;
    z-index:100;
}

.navbar-flag-icon {
    width: 33px;
    height: 20px;  
    margin-right: 5px;
}

.navbar-language-option {
    display: flex;
    padding: 10px;
    color: black;
    font-family: "SegoeUI",sans-serif;
    cursor: pointer;
}

.navbar-language-option:hover {
    background-color: #f0f0f0;
}

/* mobile */

@media (max-width: 699px) {
    .navbar-mobile {
        display: flex;
        justify-content: center;
        padding-top: 50px;
    }
}
