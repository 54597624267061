@font-face {
    font-family: "Poppins";
    src: url("../../fonts/Poppins-400.ttf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "PoppinsBold";
    src: url("../../fonts/Poppins-700.ttf") format("truetype");
    font-display: swap;
}

.footer {
    display: flex;
    flex-direction: column;
    background-color: black;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px;
    font-size: 24px;
}

.footer-content {
    display: flex;
    width: 90%;
    justify-content: space-around;
}

.footer-section {
    margin: 0 20px;
    display: flex;
    flex-direction: column;
    font-size: 24px;
}

.footer-section h4 {
    margin: 20px 0;
    margin-bottom: 40px;
    font-family: "PoppinsBold";
}

.footer-section p,
.footer-section a {
    color: white;
    font-family: "Poppins";
    text-decoration: none;
    margin: 0;
    margin-bottom: 20px;
}

/* mobile */

@media (max-width: 699px) {
    .footer-content {
        flex-direction: column;
    }
}