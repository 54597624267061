@font-face {
    font-family: "Poppins";
    src: url("../../fonts/Poppins-400.ttf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "PoppinsBold";
    src: url("../../fonts/Poppins-700.ttf") format("truetype");
    font-display: swap;
}

.terms {
    font-family: "Poppins";
    color: white;
}

@media (max-width: 599px) {
    .terms {
        transition: transform 0.3s ease, height 0.3s ease;
    }
    
    .terms.menu-open {
        transform: translateX(80%);
        transition: transform 0.3s ease;
        height: 80vh;
        margin-top: 10vh;
        overflow: auto;        
        border-radius: 20px;
    }
}

.terms-header {
    position: relative;
    text-align: center;
    color: white;
}

.terms-header-title {
    position: absolute;
    font-family: "PoppinsBold";
    font-size: 64px;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-shadow: -1px -1px 5px rgb(0, 0, 0, 0.0),
                 1px -1px 5px rgb(0, 0, 0, 0.0),
                 -1px 1px 5px rgb(0, 0, 0, 0.3),
                 1px 1px 5px rgb(0, 0, 0, 0.3);
}

.terms-header img {
    width: 100%;
    height: 70vh;
    display: block;
    object-fit: cover;
}

.terms-body.dark {
    background-color: black;
    color: white;
}

.terms-body.light {
    background-color: white;
    color: black;
}

.terms-body {
    padding: 100px 0;
}

.terms-paragraph {
    font-size: 32px;
    margin: 0;
    padding: 10px 100px;
}

.terms-paragraph a {
    text-decoration: none;
    color: inherit;
}

@media (max-width: 599px) {
    .terms-header-title {
        font-size: 48px;
    }
    
    .terms-header img {
        object-fit: cover;
    }

    .terms-body {
        padding: 30px 0;
    }
    
    .terms-paragraph {
        font-size: 20px;
        margin: 0;
        padding: 5px 30px;
    }
    
    .terms-paragraph a {
        text-decoration: none;
        color: inherit;
    }

    li {
        margin-left: 0px;
    }    
}