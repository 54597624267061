@font-face {
    font-family: "Poppins";
    src: url("../../fonts/Poppins-400.ttf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "PoppinsSemiBold";
    src: url("../../fonts/Poppins-500.ttf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "PoppinsBold";
    src: url("../../fonts/Poppins-700.ttf") format("truetype");
    font-display: swap;
}

.blog {
    font-family: "Poppins";
}

.blog-header {
    position: relative;
    margin: 0 auto;
    overflow: hidden;
    border-radius: 20px;
    padding-bottom: 100px;
    margin-bottom: 50px;
}
  
.blog-header-background {
    width: 70%;
    height: 70%;
    height: auto;
    display: block;
    margin: 0 auto;
}

.blog-header-text {
    position: absolute;
    bottom: 20px; 
    left: 20%; 
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    max-width: 30%; 
}

.blog-header-text h1 {
    font-size: 28px;
    font-family: "PoppinsSemiBold";
    margin: 0;
}

.blog-header-text p {
    font-size: 16px;
    color: #97989F;
}
  
.blog-articles {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    justify-content: center;
}

.blog-article {
    width: 25%;
    padding: 20px;
    border-radius: 5px;
    color: white;
}

.blog-article h2{
    font-family: "PoppinsBold";
    font-size: 24px;
}

.blog-article img {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.blog-article-details {
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 10px 0;
}

.blog-article-details strong {
    font-family: "PoppinsBold";
    font-size: 24px;
}

.blog-article-details p {
    font-size: 18px !important;
}

.blog-article-point {
    width: 5px !important;
    margin: 0 5px;
    padding-bottom: 2px;
}

.blog-article-share {
    height: 10px !important;
    width: 10px !important;
    padding-top: 0;
}

.blog-article p {
    font-size: 20px;
}

.blog-article-details img {
    width: 25px;
    border-radius: 50%;
}

.blog-article-bottom {
    font-size: 18px;
}

.blog-article-bottom a {
    font-size: 24px;
    font-family: "PoppinsBold";
    color: inherit;
}

.blog-show-more-container {
    text-align: center;
    padding: 50px 0;
}

.blog-show-more {
    display: inline-block;
    padding: 10px 40px; 
    background-color: #FFFFFF; 
    color: #121416;
    border: 1px solid blakc;
    border-radius: 3px;
    cursor: pointer;
    font-size: 20px;
    font-family: "PoppinsBold";
    transition: background-color 0.3s ease;
}

.blog-show-more:hover {
    background-color: #ececec;
}

.blog-article-image-container {
    position: relative;
}

.blog-article-date-container {
    position: absolute;
    top: 77%;
    left: 0px;
    background-color: white;
    padding: 5px;
    transform: rotate(-90deg);
    transform-origin: top left;
    white-space: nowrap;
    text-align: center;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.blog-article-date-container p {
    margin-bottom: 0;
    margin-top: 0;
}

@media (max-width: 1199px) {
    .blog-header {
        border-radius: 0px;
    }

    .blog-header-text {
        position: absolute;
        top: 70px; 
        right: 0px; 
        left: 50px;
        background-color: transparent;
        padding: 0px;
        border-radius: 0px;
        box-shadow: 0 0px 0px rgba(0, 0, 0, 0.1);
        max-width: 100%; 
    }

    .blog-header-text h1 {
        font-size: 20px;
        font-family: "PoppinsSemiBold";
        margin: 0;
    }
    
    .blog-header-text p {
        font-size: 16px;
        color: white;
    }

    .blog-header-background {
        display: none;
    }

    .blog-title {
        padding-left: 5%;
        font-size: 30px;
    }

    .blog-articles {
        flex-direction: column;
        flex-wrap: nowrap;
    }

    .blog-article {
        width: 100%;
        padding: 0;
        display: flex;
        flex-direction: column;
    }

    .blog-article img {
        max-width: 80%;
        padding: 0;
        align-self: center;
    }

    .blog-article h2, .blog-article div {
        max-width: 80%;
        padding: 0;
        align-self: center;
    }

    .blog-article-link {
        display: flex;
        justify-content: center;
    }    

    .blog-article-image-container img {
        max-width: 100%;
    }

    .blog-header {
        padding-bottom: 30px;
        margin-bottom: 20px;
    }
}