@font-face {
    font-family: "Poppins";
    src: url("../../fonts/Poppins-400.ttf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "PoppinsBold";
    src: url("../../fonts/Poppins-700.ttf") format("truetype");
    font-display: swap;
}

.article-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: "Poppins";
    color: white;
}
  
.article-content {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}
  
.article-left-container {
    padding: 40px;
    padding-top: 0;
    max-width: 65%;
    box-sizing: border-box;    
}

.article-left-container div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

@media (max-width: 599px) {
    .article-content {
        display: flex;
        flex-direction: column;
    }

    .article-author-container {
        order: -1;
    }
}

@media (max-width: 1199px) {
    .article-page {
        transition: transform 0.3s ease, height 0.3s ease;
    }
    
    .article-page.menu-open {
        transform: translateX(80%);
        transition: transform 0.3s ease;
        height: 80vh;
        margin-top: 10vh;
        overflow: auto;        
        border-radius: 20px;
    }

    .article-header-image h1 {
        font-size: 20px;
        max-width: 90%;
        margin-top: 100px;
    }

    .article-left-container {
        padding: 40px;
        padding-top: 0;
        max-width: 100%;
        box-sizing: border-box;    
    }

    .article-left-container img {
        max-width: 100%;
        height: 100%;
    }
}