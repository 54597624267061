@font-face {
    font-family: "PoppinsExtra";
    src: url("../../fonts/Poppins-900.ttf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "PoppinsBold";
    src: url("../../fonts/Poppins-700.ttf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "PoppinsSemi";
    src: url("../../fonts/Poppins-500.ttf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "Poppins";
    src: url("../../fonts/Poppins-400.ttf") format("truetype");
    font-display: swap;
}

.video-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.video-modal-content {
    position: relative;
    width: 80%;
    max-width: 1000px;
    background-color: #000;
    padding: 20px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.video-modal-content iframe {
    width: 100%;
    height: 500px;
}

.home-section1 {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    background-color: #000;
    color: #fff;
}

.home-section1-content {
    max-width: 30%;
    padding: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.home-section1-content h1 {
    font-size: 48px;
    font-family: "PoppinsExtra";
    margin-bottom: 20px;
}

.home-section1-highlight1 {
    color: white;
}
  
.home-section1-highlight2 {
    color: #3D3D3E;
}

.home-section1-highlight1,
.home-section1-highlight2 {
    transition: color 0.3s ease; 
}

.home-section1-highlight1:hover,
.home-section1-highlight2:hover {
    color: #BC3140;
}

.home-section1-description {
    font-size: 16px;
    font-family: "PoppinsSemi";
    color: #AEAEB2;
    margin-bottom: 30px;
}
  
.home-section1-link {
    font-size: 16px;
    font-family: "PoppinBold";
    color: #BC3140;
    text-decoration: none;
    font-weight: bold;
    margin-bottom: 30px;
    display: inline-block;
    cursor: pointer;
}
  
.home-section1-buttons {
    display: flex;
    gap: 15px;
    cursor: pointer;
    transition: transform 0.3s ease;
}

.home-section1-app-store:hover, .home-section1-google-play:hover {
    transform: scale(1.05);
}

.home-section1-btn img {
    width: 150px;
    height: auto;
}
  
.home-section1-images {
    position: relative;
    flex: 1; 
    display: flex;
    align-items: flex-end; 
    justify-content: center;
    overflow: hidden;
}

.home-section1-images img {
    max-width: 100%;
    max-height: 100%; 
    object-fit: contain;
}

/* section 2 */

.home-section2 {
    display: flex;
    flex-direction: column;
    padding: 50px;
    background-color: #1c1c1c;
    color: white;
}
  
.home-section2-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
  
.home-section2-image {
    flex: 1;
    display: flex;
    justify-content: center;
}

.home-section2-image img {
    max-width: 75%;
    height: auto;
    transition: transform 0.3s ease; 
}

.home-section2-image img:hover {
    transform: scale(1.03);
}
  
.home-section2-text {
    flex: 1;
    padding-left: 40px;
}

.home-section2-text h2 {
    font-size: 40px;
    font-family: "PoppinsBold";
    margin-bottom: 20px;
}

.home-section2-text p {
    font-size: 16px;
    font-family: "PoppinsSemi";
    margin-bottom: 40px;
    color: #AEAEB2;
}

.home-section2-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;
}

.home-section2-item-title {
    display: flex;
    justify-content: center;
    align-items: center;
}

.home-section2-item img {
    margin-right: 20px;
    width: 50px;
}

.home-section2-item h3, .home-section2 h3 {
    font-size: 24px;
    font-family: "PoppinsBold";
}

.home-section2-item p, .home-section2 p {
    color: #AEAEB2;
    font-size: 16px;
    font-family: "PoppinsSemi";
}

.home-section2-footer {
    padding: 50px 100px;
}

/* Section 3 */
.home-section3 {
    display: flex;
    flex-direction: column;
    background-color: black;
    color: white;
    padding: 50px 0;
}
  
.home-section3-content, .home-section3-content2 {
    width: 100%;
    display: flex;
    margin: 50px auto;
    max-width: 1200px;
    border-radius: 29px;
}

.home-section3-content {
    background-color: #1A1A1A;
    transition: transform 0.3s ease; 
}

.home-section3-content:hover {
    transform: scale(1.05);
}

.home-section3-text, .home-section3-text2 {
    flex: 1;
    text-align: left;
    padding-left: 50px;
    background-color: #1A1A1A;
    border-radius: 29px;
}

.home-section3-text2 {
    padding-right: 100px;
    transition: transform 0.3s ease;
}

.home-section3-text2:hover {
    transform: scale(1.05);
}

.home-section3-text h2, .home-section3-text2 h2 {
    font-size: 36px;
    font-family: "PoppinsBold";
    margin-bottom: 20px;
}

.home-section3-text p, .home-section3-text2 p {
    font-size: 14px;
    color: #AEAEB2;
    font-family: "PoppinsSemi";
}

.home-section3-images {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    height: 100%;
    flex: 1;
}

.home-section3-phone, .home-section3-phone2 {
    height: 100%;
    width: auto;
    object-fit: contain;
    margin: 0;
}
  
.home-section3-phone2 {
    background-color: #1A1A1A;
    border-radius: 29px;
}

.reverse {
    justify-content: flex-start;
    align-items: flex-start;
    transition: transform 0.3s ease;
}

.reverse:hover {
    transform: scale(1.05);
}

/* Section4 */
.home-section4 {
    background-color: #1A1A1A;
    padding: 70px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.home-section4-content {
    text-align: center;
    max-width: 80%;
    width: 100%;
    color: white;
    padding: 30px;
    border-radius: 25px;
    background-color: #560D2C;
    transition: transform 0.3s ease;
}

.home-section4-content:hover {
    transform: scale(1.05);
}

.home-section4 h2 {
    font-size: 32px;
    font-family: "PoppinsBold";
    margin-bottom: 10px;
}

.home-section4 p {
    font-size: 20px;
    font-family: "Poppins";
    margin-bottom: 30px;
}

.home-section4-buttons {
    display: flex;
    justify-content: center;
    gap: 20px;
}

.home-section4-button {
    width: 150px;
    cursor: pointer;
    transition: transform 0.3s ease;
}

.home-section4-button:hover {
    transform: scale(1.05);
}

/* mobile */

@media (max-width: 699px) {
    .home-section1 {
        flex-direction: column;
    }

    .home-section1-content {
        max-width: 100%;
        padding: 20px;
    }

    .home-section1-content h1 {
        font-size: 36px;
    }

    .home-section2 {
        padding: 20px;
    }

    .home-section2-text {
        padding-left: 0px;
    }

    .home-section2-image {
        display: none;
    }

    .home-section2-text p {
        margin-bottom: 0px;
    }

    .home-section2-footer {
        padding: 20px;
    }

    .home-section3 {
        padding: 0 20px;
    }

    .home-section3-content {
        flex-direction: column;
    }

    .home-section3-text, .home-section3-text2 {
        padding-left: 20px;
    }

    .home-section3-phone, .home-section3-phone2 {
        border-radius: 29px;
        width: 100%;
    }

    .home-section3-content2 {
        width: 100%;
        display: flex;
        margin: 50px auto;
        margin-top: 10px;
    }

    .home-section3-text2 {
        padding-right: 20px;
    }

    .reverse {
        display: none;
    }

    .home-section4-content {
        padding: 10px;
        max-width: 95%;
    }

    .home-section4 h2 {
        font-size: 24px;
    }

    .home-section4 p {
        font-size: 16px;
    }
}

/* tablet */

@media (min-width: 700px) and (max-width: 1200px) {
    .home-section1-content {
        max-width: 30%;
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .home-section1-content h1 {
        font-size: 32px;
    }

    .home-section1-description {
        font-size: 12px;
    }

    .home-section2 {
        padding: 20px;
    }

    .home-section2-image img {
        max-width: 85%;
    }

    .home-section2-text {
        padding-left: 10px;
    }

    .home-section2-text h2 {
        font-size: 28px;
    }

    .home-section2-text p {
        font-size: 12px;
        margin-bottom: 0;
    }

    .home-section2-footer {
        padding: 20px 40px;
    }

    .home-section2-item {
        margin-bottom: 0px;
    }

    .home-section3 {
        display: flex;
        flex-direction: column;
        background-color: black;
        color: white;
        padding: 20px;
    }

    .home-section3-text h2, .home-section3-text2 h2 {
        font-size: 30px;
        margin-bottom: 10px;
    }

    .home-section3-text2 {
        padding-right: 10px;
    }

    .home-section3-text2 {
        padding-left: 20px;
    }

    .home-section4-content {
        text-align: center;
        max-width: 90%;
        width: 100%;
    }

    .home-section4 h2 {
        font-size: 28px;
    }

    .home-section4 p {
        font-size: 16px;
    }
}


  
  
  
  

  